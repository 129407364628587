<template>
  <ChartCard
    id="MobileTradeArea"
    :isLoadingToLocation="isLoadingToLocation"
  >
    <template #icon>
      <img :src="require('pinnacle-lib/assets/icon/origin-clock.svg')" alt="stopwatch" />
    </template>
    <template #title>
      <h2>Mobile Trade Area</h2>
      <p>An estimate of the average drive time to each location for visitors.</p>
    </template>
    <template #dropdown>
      <b-button @click="saveCSV('Mobile Trade Area')" class="exportBtn" variant="outline-primary">Export CSV</b-button>
    </template>
    <template #name-card>
      <ReportsLegend
        chart-type="dayWeek"
        :studyLocations="studyLocations"
        v-bind:insufficient-data-ids="insufficientDataIds"
      ></ReportsLegend>
    </template>
    <template #charts-container>
      <div ref="mapComponent" class="map-container" :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"></div>
      <Basemaps :mapUpdatedCallback="changeMapStyle"></Basemaps>

      <div class="popup-info-container" v-show="popup_data.items.length > 0">
        <h4>Drive Time</h4>
        <h5>{{ popup_data.geolabel }} {{ popup_data.title }}</h5>
        <ul class="list-unstyled map-tooltip-list">
          <li v-for="(item, index) in popup_data.items" :key="item.id">
            <div class="squareN" :style="{'background-color': `rgb(${item.color.join(',')})`}"></div>
            <div class="popup_data_label">{{  item.label }}</div>
            <div class="popup_data_value">{{  item.value }}</div>
          </li>
        </ul>
      </div>      
      <div class="geolevel-container">
        Geography Level
        <b-form-select v-model="geolevel" :options="geolevel_options"></b-form-select>     
      </div>   
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </template>
  </ChartCard>
</template>
<script>
import { isEqual } from "lodash";
import LineChart from "@/components/charts/LineChart";
import BarChart from "@/components/charts/BarChart";
import ReportsLegend from "../ReportsLegend.vue";
import Basemaps from "@/components/partials/Basemaps.vue";
import { bus } from "@/main";
import {
  getLineGraphOptionsNoTime,
  getBarGraphOptionsNoTime,
} from "@/config/chart";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "@/services/charts/download";
import ChartUtilMixin from "@/services/charts/chartUtil.js";
import ChartCard from "./ReportChartCard.vue";
import Papa from 'papaparse';
import PmTilesSource from "@/services/map/PmTilesSource";


export default {
  components: {
    BarChart,
    LineChart,
    ReportsLegend,
    ChartCard,
    Basemaps,
  },
  mixins: [ChartsDownloadMixin, MultiDropdownMixin, ChartUtilMixin],
  //mixins: [MultiDropdownMixin],
  data() {
    return {
      attempt: false,
      week: {
        line: {
          data: null,
          option: null,
        },
        bar: {
          data: null,
          option: null,
        },
      },
      chartType: {
        month: "line",
        day: "bar",
        week: "bar",
      },
      isLoading: true,
      insufficientDataIds: [],
      show: [true, true, true, true, true],
      showRange: true,
      showWarn: false,
      chartRefs: ["dayOfWeekLineChart", "dayOfWeekBarChart"],
      aggData: {},
    };
  },
  props: {
    filterString: {
      type: String,
    },
    // id: {
    //   type: Array,
    // },
    studyLocations: {
      type: Array,
      default: function () {
        return [];
      },      
    },
  },
  methods: {
    changeRange(){
      console.log(this.showRange)
      this.loadChart();
    },
    saveCSV(filename) {
      let csv_fields = [];
      let csv_data = [];

      csv_fields.push("GEOID");
      let place_mappings = {};
      this.studyLocations.forEach(l => {
        let label = l.name + " " + l.address;
        csv_fields.push(label);
        place_mappings[l.id] = label;
      });
      let geoids = [];
      this.aggData[this.geolevel].rows.forEach(r => {
        if (!geoids.includes(r.geoid)) {
          geoids.push(r.geoid);
        }
      });
      geoids.sort();
      geoids.forEach(geoid => {
        let csv_row = [];
        csv_row.push(geoid);
        this.studyLocations.forEach(l => {
          let match = this.aggData[this.geolevel].rows.find(r => (r.polygon_id == l.id && r.geoid == geoid));
          if (match) {
            csv_row.push(match.drive_time)
          } else {
            csv_row.push(null);
          }
        });
        csv_data.push(csv_row);
      })
      var csv = Papa.unparse({
        "fields": csv_fields,
        "data": csv_data
      });
      let data =
        "data:text/csv;charset=utf-8," +
        csv;
      if (typeof filename !== "undefined") {
        saveAs(data, `${filename}.csv`);
      } else {
        return data;
      }
    },
    changeView(view) {
      if (!isEqual(view, this.chartType)) {
        this.chartType = { ...this.chartType, ...view };
      }
    },
    createData(weekEntries) {
      const { color } = this.chartColors;
      for (let i = 0; i < this.ids.length; i++) {
        let polygon = {};
        polygon = {
          label: this.location[i].shortenedName,
          data: weekEntries[this.ids[i]].map((values) =>
            values.percent.toFixed(2)
          ),
          borderColor: color[i],
          backgroundColor: color[i],
          fill: false,
          hidden: !this.show[i],
          categoryPercentage: 0.3,
          barPercentage: 1,
          datalabels: {
            display: false,
          },
        };
        this.week.line.data.datasets.push(polygon);
        this.week.bar.data.datasets.push(polygon);
      }
    },
    setData(res) {
      const [weekEntries, median] = res;
      if (!Object.entries(weekEntries).length) return;
      // calculate min_line and max_line
      const { minLine, maxLine } = this.calcMinMax(this.weekData["series_data"], "percent", "dow");
      this.week = {
        line: {
          data: {
            weekData: [],
            labels: median.map((entry) => entry.label),
            datasets: [
              {
                label: this.medianName + ":",
                data: median.map((values) => values.percent.toFixed(2)),
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                type: "line",
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Max",
                data: maxLine.map(({ ...values }) =>
                  values.percent.toFixed(2)
                ),
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
              {
                label: "Min",
                data: minLine.map(({ ...values }) =>
                  values.percent.toFixed(2)
                ),
                borderColor: "rgba(177, 194, 217, 0)",
                fill: "+1",
                hidden: !this.showRange,
                // backgroundColor: "rgba(177, 194, 217, 0.2)",
                backgroundColor: "#F8FAFD",
                datalabels: {
                  display: false,
                },
              },
            ],
          },
          option: getLineGraphOptionsNoTime({
            labelStringXAxes: "Day of Week",
            labelStringYAxes: "Percent of Visits",
            tooltipSuffix: "%",
          }),
        },
        bar: {
          data: {
            labels: median.map((entry) => entry.label),
            datasets: [
              {
                label: this.medianName + ":",
                data: median.map((values) => values.percent.toFixed(2)),
                borderColor: "#B1C2D9",
                borderDash: [20, 10],
                borderWidth: 1,
                backgroundColor: "#B1C2D9",
                fill: false,
                hidden: !this.showMedian,
                type: "line",
                datalabels: {
                  display: false,
                },
              },
              {},
              {},
            ],
          },
          option: getBarGraphOptionsNoTime({
            labelStringXAxes: "Day of Week",
            labelStringYAxes: "Percent of Visits",
            tooltipSuffix: "%",
          }),
        },
      };
      this.createData(weekEntries);
      //this.saveCSV();
      if (this.$refs.dayOfWeekBarChart && this.$refs.dayOfWeekBarChart.render) {
        this.$refs.dayOfWeekBarChart.render();
      }
    },
    loadChart() {
      this.isLoading = true;
      this.initMap();
      this.aggData = {};
      this.requestData().then((res) => {
        
        //this.setData(res);
        this.aggData = res;
        //console.log(this.aggData);
        this.loadMap();
        this.isLoading = false;
      });
    },
    loadMap() {
      if (this.mapLoaded && this.aggData && this.aggData[this.geolevel]) {
        //console.log('data ready');
      } else {
        //console.log('waiting for data');
        setTimeout(this.loadMap, 1000);
        return;
      }

      this.addStudyLocationMarkers();

      let geo_table_mapping = this.geo_table_mapping;
      let source_prefix = 'geo-source';
      this.cleanUpSources(source_prefix);
      let geolevel_option = this.geolevel_options.find(g => g.value == this.geolevel);
      let geo_levels = geolevel_option.geo_levels;

      let PMTILES_URL = geolevel_option.tile_url;
      let source_id = `${source_prefix}`;
      console.log(PMTILES_URL);
      this.map.addSource(source_id, {
        type: PmTilesSource.SOURCE_TYPE,
        url: PMTILES_URL,
        "promoteId":  this.getPromiteIds()
      });

      //this.map.showTileBoundaries = true;

      geo_levels.forEach(geo_level => {
        let layer_prefix = `geo-layer-${geo_level}`;
        
        let {id_field, source_layer} = geo_table_mapping[geo_level];

        this.studyLocations.forEach(loc => {
          let id = loc.id;
          let visibility = 'visible';
          if (!loc.show) visibility = 'none';
          this.map.addLayer({
            id: `${layer_prefix}-${id}`,
            source: source_id,
            "source-layer": source_layer,
            type: "fill",
            'layout': {
              'visibility': visibility,
            },
            'paint': {
              'fill-color': this.generateWeightedPaintExpression(this.aggData[geo_level].rows, "drive_time", id_field, id),
              'fill-opacity': 0.75,
              'fill-outline-color': 'white'
            }
          });
        });
        this.addHoverLayer(source_id, geo_level);
      });
    },
    async requestData() {
      this.showWarn = false;
      let promises = [];
      let geolevel_option = this.geolevel_options.find(g => g.value == this.geolevel);
      let geo_levels = geolevel_option.geo_levels;
      geo_levels = geo_levels.filter(l => l != "province");
      geo_levels.forEach(geo_level => {
        promises.push(this.getChartData(`/v2/daily-visits-trade-area/${this.$route.params.id}`, {geolevel: geo_level}));
      })
      const responses = await Promise.allSettled(promises);
      let data = {};
      geo_levels.forEach((geo_level, index) => {
        data[geo_level] = responses[index].value.data;
      });
      return data;
    },
    onCallback() {
      console.log('onCallback');
    },
    populatePopupItems(matches) {
      let items = [];
      matches.forEach(r => {
        let id = r.polygon_id;
        let loc = this.studyLocations.find(l => l.id == id);
        let color = this.getStudyLocationColor(id);
        let value = r["drive_time"];
        if (value < 60) {
          value = Math.round(value) + " min";
        } else {
          value = value / 60;
          value = Math.round(value) + " hr";
        }
        let item = {
          id: id,
          color: color,
          label: loc.name,
          value: value,
        }
        items.push(item);
      });
      return items;
    },
  },
  mounted() {
    //this.registerMultiDropdown(this.$refs.weekOptionsMenu);
    // let ids = this.ids;
    // let tempArr = [true, true, true, true, true];
    // Object.entries(this.disabledIds).forEach(function (o) {
    //   if (ids.indexOf(o[0]) !== undefined) {
    //     tempArr[ids.indexOf(o[0])] = false;
    //   }
    // });
    // this.show = tempArr;
    if (this.filterString) {
      this.loadChart();  // Wait until filters event before populating chart
    }
    
    // bus.$on("locationsListChanged", (res) => {
    //   const rangeBandData = this.calcMinMax(this.weekData.series_data, "percent", "dow");
    //   this.handleLocationsListChanged(res, this.week, rangeBandData, (line) => {
    //     return line.map(values => values.percent.toFixed(2));
    //   });
    //   this.renderChartRefs();
    // });
    // bus.$on("filters", (res) => {
    //   //this.filterString = res;
    //   this.isLoading = true;
    //   if (this.isLoadingToLocation === false) {
    //     this.requestData().then((res) => {
    //       this.setData(res);
    //     });
    //   } else {
    //     this.attempt = true;
    //   }
    // });
    // bus.$on("onHover", (res) => this.handleOnHover(res));
  },
  computed: {
    showMedian() {
      return true;
      //return this.$sessionStore.state.study.showMedian;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
    ids() {
      return this.studyLocations.map((loc) => loc.id)
    },
    showArray() {
      return this.studyLocations.filter((loc) => loc.show).map(l => l.id);
    },
  },
  watch: {
    filterString() {
      this.loadChart();  // Wait until filters event before populating chart
    },
    isLoadingToLocation() {
      // if (!this.isLoadingToLocation && this.attempt) {
      //   this.requestData().then((res) => {
      //     this.setData(res);
      //   });
      // }
    },
    geolevel(new_geolevel) {
      console.log('geoLevel changed', new_geolevel);
      this.loadChart();
    },
    showArray(arr) {
      if (!this.mapLoaded) return;

      let geolevel_option = this.geolevel_options.find(g => g.value == this.geolevel);
      let geo_levels = geolevel_option.geo_levels;

      geo_levels.forEach(geo_level => {
        let layer_prefix = `geo-layer-${geo_level}`;
        this.ids.forEach((polygonId, index) => {
          if (arr.includes(polygonId)) {
            this.map.setLayoutProperty(`${layer_prefix}-${polygonId}`, 'visibility', 'visible');
          } else {
            this.map.setLayoutProperty(`${layer_prefix}-${polygonId}`, 'visibility', 'none');
          }
        });
      });
    },
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
};
</script>

<style lang="scss" scoped>
@import "./ReportChartStyles.scss";
</style>