<template>
  <div>
    <div class="section">
      <div class="alert alert-warning timeframe-alert" v-if="showTimeFrameAlert">
        Azira has updated our device data retention policy to store the previous three full calendar years plus the current calendar year of data. This change ensures best data management practices and compliance with industry standards. 
        Users are now able to access data from {{ minDateFormated }} and later. 
        Please contact 
        <a href="mailto:support@azira.com">support@azira.com </a>
        with any questions. 
      </div>
      <CreateHeader :useRouteMeta="true" />
    </div>
    
    <div class="section">
      <Timeframe :disableDayparts="$route.name.includes('report')" :minDate="minDate" :maxDate="maxDate" />
    </div>
  </div>
</template>

<script>
import CreateHeader from "./CreateHeader.vue";
import Timeframe from "../../../dataExplorer/Timeframe.vue";
import moment from "moment";

export default {
  components: { CreateHeader, Timeframe },
  computed: {
    showTimeFrameAlert() {
      return !this.$route.name.includes('visits-report')
    },
    minDateFormated() {
      return moment(this.minDate).format("LL");
    },
    minDate() {
      if (this.$route.name.includes('visits-report')) {
        return "2024-01-01";
      } else {
        let companyId = this.$persistingStore.state.user?.company?.id;
        let specialCompanyIds = [1, 5, 18, 23, 104, 590, 1792, 2790, 2878, 3038, 3159];
        if (specialCompanyIds.includes(companyId)) {
          return "2019-01-01";
        } else {
          return "2022-01-01";
        }
        
      }
    },
    maxDate() {
      if (this.$route.name.includes('visits-report')) {
        return moment().add(1, "year").format("YYYY-MM-DD");
      } else {
        return moment().subtract(2, "days").format("YYYY-MM-DD");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timeframe-alert {
  background-color:#fff3cd;
  border-color: #fff3cd;
}
</style>