<template>
  <div id="home-page" class="home-page-container">
    <div v-if="showBanner">
    <b-alert show dismissible variant="warning" style="position: absolute; z-index: 1000;width: calc( 100vw - 100px )">
      <!--We are experiencing issues which may result in processing delays for Pinnacle Reports and Datasets.
      We apologize for the inconvenience.-->
      <!-- The queue is active and running. However, there is a higher than normal volume of jobs, therefore completion times may be delayed. -->
      Our site is currently undergoing scheduled maintenance to the Data API product to improve your experience and security. Expected down time is roughly seven hours and this banner will be removed to indicate the maintenance has been completed. 
      <br />
      Thank you for your patience and please reach out to 
      <a href="mailto:support@azira.com">support@azira.com</a>
      with any concerns or questions.
    </b-alert>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { GetMixin } from "pinnacle-lib";
export default {
  mixins: [GetMixin],
  data() {
    return {
      showBanner: false,
    };
  },
  mounted() {
    //console.log('HomePage mounted');
    this.loadBanners();
  },
  methods: {
    async loadBanners() {   
      let urlStr = `${process.env.VUE_APP_PINNACLE_API}/v2/active-banners`;
      let data = await this.getFromURL(urlStr, { });

      //console.log(data);
      if (data?.records?.length > 0) {
        this.showBanner = true;
      } else {
        this.showBanner = false;
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page-container {
  overflow: auto;
}
</style>