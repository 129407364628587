<template>
  <div class="timeframe">
    <div>
      <!-- <p class="required">*Required</p>
      <h2>Set Timeframe</h2>
      <h3>Select the timeframe for the dataset in UTC time</h3> -->
      <div v-if="Object.values(errors).length > 0" class="mb-4">
        <p class="error-msg" v-for="error in Object.values(errors)" :key="error">{{ error }}</p>
      </div>
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex">
          <div class="d-flex flex-column">
            <label for="previous" class="subtext">Previous</label>
            <div name="previous">
              <b-button
                @click="prevMonth"
                :class="{ 'btn-active': activeBtn === 'month' }"
                variant="outline-primary"
                >Month</b-button
              >
              <b-button
                @click="prevYear"
                :class="{ 'btn-active': activeBtn === 'year' }"
                variant="outline-primary"
                class="ml-2"
                >Year</b-button
              >
            </div>
          </div>
          <div class="d-flex flex-column ml-7">
            <label for="trailing" class="subtext">Trailing</label>
            <div name="trailing">
              <b-button
                @click="trailing30Days"
                :class="{ 'btn-active': activeBtn === '30Days' }"
                variant="outline-primary"
                >30 Days</b-button
              >
              <b-button
                @click="trailing60Days"
                :class="{ 'btn-active': activeBtn === '60Days' }"
                variant="outline-primary"
                class="ml-2"
                >60 Days</b-button
              >
              <b-button
                @click="trailing3Months"
                :class="{ 'btn-active': activeBtn === '3Months' }"
                variant="outline-primary"
                class="ml-2"
                >3 Months</b-button
              >
              <b-button
                @click="trailing6Months"
                :class="{ 'btn-active': activeBtn === '6Months' }"
                variant="outline-primary"
                class="ml-2"
                >6 Months</b-button
              >
              <b-button
                @click="trailing1Year"
                :class="{ 'btn-active': activeBtn === '1Year' }"
                variant="outline-primary"
                class="ml-2"
                >1 Year</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-container">
        <div class="mb-2">
          <label class="subtext">Start Date and Time</label>
          <b-input-group>
            <b-form-input
              id="start-date-input"
              v-model="startDate"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="startDate"
                button-only
                center
                locale="en-US"
                aria-controls="start-date-input"
                :no-flip="noFlip"
                :min="minDate"
                :max="maxDate"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="start-time-input"
              v-model="startTime"
              type="text"
              placeholder="00:00:00"
              autocomplete="off"
            ></b-form-input>
          </b-input-group>
        </div>
        <div>
          <label class="subtext">End Date and Time</label>
          <b-input-group>
            <b-form-input
              id="end-date-input"
              v-model="endDate"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="endDate"
                button-only
                right
                locale="en-US"
                aria-controls="end-date-input"
                :no-flip="noFlip"
                :max="maxDate"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-form-input
              id="end-time-input"
              v-model="endTime"
              type="text"
              placeholder="23:59:59"
              autocomplete="off"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <label class="subtext">
        The latest possible end date is two dates prior to when this job is
        scheduled to run. Data is available from 
        <span>{{ minDateFormatted }}.</span>
        
        <!-- Data is available from 
        January 1, 2018 for most places, except those in the EU, 
        for which it is available from January 1, 2019. -->
        <span class="required">Please note that these times are in UTC.</span>
      </label>
      <br />
      <div v-for="[index, error] of timeframeErrors.entries()" :key="index">
        <p class="required">{{ error }}</p>
      </div>
      <!-- day parts -->
      <b-button
        class="dayparts-button"
        v-b-toggle.day-parts-collapse
        variant="primary"
        v-if="!disableDayparts"
        @click="
          firstDayPart();
          collapseToggle();
        "
        >Day Parts
        <img v-if="dayPartCollapse" :src="require('pinnacle-lib/assets/icon/down_light.svg')" />
        <img v-else :src="require('pinnacle-lib/assets/icon/up_light.svg')" />
        </b-button
      >
      <b-collapse id="day-parts-collapse">
        <b-card>
          <table class="table">
            <thead>
              <tr>
                <th scope="col"><div class="days">M</div></th>
                <th scope="col"><div class="days">T</div></th>
                <th scope="col"><div class="days">W</div></th>
                <th scope="col"><div class="days">Th</div></th>
                <th scope="col"><div class="days">F</div></th>
                <th scope="col"><div class="days">S</div></th>
                <th scope="col"><div class="days">Su</div></th>
                <th scope="col"><div class="days">From</div></th>
                <th scope="col"><div class="days">To</div></th>
                <th scope="col"><div class="days"></div></th>
              </tr>
            </thead>
            <tbody>
              <DayParts
                v-for="part in dayParts"
                :key="part.id"
                :presets="part"
              />
            </tbody>
          </table>
        </b-card>
        <label class="subtext" style="margin-bottom: 1rem">
          Note: Dayparting times are in local timezones.<br />
          But please note that this is still bound by the Start and End
          Date/Time selected for this job</label
        >
        <div v-for="[index, error] of dayPartErrors.entries()" :key="index">
          <p class="required">{{ error }}</p>
        </div>
        <div class="w-100 d-flex justify-content-end">
          <b-button v-b-modal.close-day-parts variant="outline-primary"
            >Clear</b-button
          >
          <b-modal
            id="close-day-parts"
            title="Are you sure?"
            ok-title="Yes"
            header-class="modal-title"
            content-class="modal-content"
            @ok="clearDayParts"
            centered
          >
            Are you sure you want to remove all Day Parts?
          </b-modal>
          <b-button
            style="margin-left: 0.5rem"
            @click="addDayPart()"
            variant="primary"
            >Add Row</b-button
          >
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import moment from "moment";
import DayParts from "./DayParts.vue";
import DEConstants from "@/services/dataExplorer/constants.js";

export default {
  components: {
    DayParts,
  },
  mixins: [DEConstants],
  props: ["disableDayparts", "minDate", "maxDate"],
  data() {
    return {
      errors: {},
      startDate: "",
      endDate: "",
      startTime: "00:00:00",
      endTime: "23:59:59",
      dayParts: [],
      dayPartIndex: 0, // used to assign an id to each dayPart
      dayPartCollapse: true, // tracks whether dayPart section is collapsed or not
      dayPartErrors: [],
      timeframeErrors: [],
      noFlip: true,
      activeBtn: "",
      previousTimeframeError: undefined
    };
  },
  mounted() {
    // checks if query already exists and populates page with store data if so
    this.loadFromStore();
    // removes specific dayPart from array
    bus.$off("destroyDayPart");
    bus.$on("destroyDayPart", (key) => {
      for (let [index, part] of this.dayParts.entries()) {
        if (part.id === key) {
          this.dayParts.splice(index, 1);
        }
      }
    });

    // updates existing dayPart with new values
    bus.$off("updateDayPart");
    bus.$on("updateDayPart", (dayPart) => {
      for (let [index, part] of this.dayParts.entries()) {
        if (part.id === dayPart.id) {
          this.dayParts.splice(index, 1, dayPart);
          // using splice to mutate array so that dayPart watcher can detect change
        }
      }
    });

    // adds error message when times or dates are invalid
    bus.$off("invalidTimeframe");
    bus.$on("invalidTimeframe", (message) => {
      for (const error of this.timeframeErrors) {
        if (error === message) {
          return;
        }
      }
      this.timeframeErrors.push(message);
    });

    // removes error message when times or dates are valid
    bus.$off("validDateTime");
    bus.$on("validDateTime", (message) => {
      for (let [index, error] of this.timeframeErrors.entries()) {
        if (error === message) {
          this.timeframeErrors.splice(index, 1);
        }
      }
    });

    // adds error message if dayParts data isn't valid
    bus.$off("invalidDayPart");
    bus.$on("invalidDayPart", (message) => {
      for (const error of this.dayPartErrors) {
        if (error === message) {
          return;
        }
      }
      this.dayPartErrors.push(message);
    });

    // removes error message if no longer present in any dayPart
    bus.$off("validDayPart");
    bus.$on("validDayPart", (message) => {
      for (let [index, error] of this.dayPartErrors.entries()) {
        if (error === message && this.validDayParts) {
          this.dayPartErrors.splice(index, 1);
        }
      }
    });
  },
  methods: {
    // the 7 following methods all calculate the proper start and end dates based on the button pressed
    // e.g. prevMonth() sets the startDate as the first day of the last month and the endDate as the last day of the last month
    prevMonth() {
      this.startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    prevYear() {
      this.startDate = moment()
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .subtract(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    },
    trailing30Days() {
      this.startDate = moment().subtract(31, "days").format("YYYY-MM-DD");
      this.endDate = this.twoDaysAgo;
    },
    trailing60Days() {
      this.startDate = moment().subtract(61, "days").format("YYYY-MM-DD");
      this.endDate = this.twoDaysAgo;
    },
    trailing3Months() {
      this.startDate = moment()
        .subtract(2, "days")
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.endDate = this.twoDaysAgo;
    },
    trailing6Months() {
      this.startDate = moment()
        .subtract(2, "days")
        .subtract(6, "months")
        .format("YYYY-MM-DD");
      this.endDate = this.twoDaysAgo;
    },
    trailing1Year() {
      this.startDate = moment()
        .subtract(2, "days")
        .subtract(1, "years")
        .format("YYYY-MM-DD");
      this.endDate = this.twoDaysAgo;
    },
    addDayPart(dayParts) {
      // this logic will push the stored dayParts into the current dayPart array
      if (dayParts !== undefined) {
        this.clearDayParts();
        for (const dayPart of dayParts) {
          this.dayParts.push(dayPart);
          this.dayPartsIndex++;
        }
        this.collapseToggle();
        this.$root.$emit("bv::toggle::collapse", "day-parts-collapse");
        return;
      }
      // adds a blank dayPart to the dayParts array and increments dayPartIndex
      this.dayParts.push({
        daysOfWeek: [],
        startTime: "00:00:00",
        endTime: "23:30:00",
        id: this.dayPartIndex,
      });
      this.dayPartIndex++;
      // adds checkbox warning if there are more dayParts than the first
      if (this.dayParts.length > 1) {
        bus.$emit(
          "invalidDayPart",
          "Each Day Part must have at least one day checked"
        );
      }
    },
    firstDayPart() {
      // adds a dayPart when dayPart collapse is first opened
      if (this.dayParts.length < 1 && this.dayPartCollapse === true) {
        this.addDayPart();
      }
    },
    clearDayParts() {
      // removes all dayParts
      this.dayParts = [];
      this.dayPartIndex = 0;
    },
    collapseToggle() {
      // updates dayPartCollapse value (used for firstDayPart)
      this.dayPartCollapse = !this.dayPartCollapse;
    },
    loadFromStore() {
      const { startDateTime, endDateTime, dayParts } = this.$sessionStore.state.jobRequest;
      if (startDateTime !== undefined && startDateTime !== '') {
        const storedStartDateTime = startDateTime.split(' ');
        this.startDate = storedStartDateTime[0];
        this.startTime = storedStartDateTime[1];
      }
      if (endDateTime !== undefined && endDateTime !== "") {
        const storedEndDateTime =
          this.$sessionStore.state.jobRequest.endDateTime.split(" ");
        this.endDate = storedEndDateTime[0];
        this.endTime = storedEndDateTime[1];
      }
      if (dayParts !== undefined) {
        this.addDayPart(this.$sessionStore.state.jobRequest.dayParts);
      }
    }
  },
  computed: {
    minDateFormatted() {
      return moment(this.minDate).format("LL");
    },
    twoDaysAgo() {
      // calculates the date from two days ago, the upper limit for searchable timeframe
      return moment().subtract(2, "days").format("YYYY-MM-DD");
    },
    validDates() {
      // calculates whether the dates entered are valid
      const startDate = moment(this.startDate, "YYYY-MM-DD", true);
      const validStartDate = startDate.isValid();

      if (!validStartDate) {
        return {valid: false, reason: "Start date is invalid."};
      }

      const endDate = moment(this.endDate, "YYYY-MM-DD", true);
      const validEndDate = endDate.isValid();

      if (!validEndDate) {
        return {valid: false, reason: "End date is invalid."};
      }

      if (startDate > endDate) {
        return {valid: false, reason: "A job’s end date and time must be after the start date and time"};
      }

      const twoDaysAgo = this.maxDate;//moment().subtract(2, "days");
      if (
        moment(this.startDate) > twoDaysAgo ||
        moment(this.endDate) > twoDaysAgo ||
        moment(this.startDate) > moment(this.endDate)
      ) {
        return {valid: false, reason: "You must select an end date more than two days prior to the current date."};
      }
      
      if (startDate.isBefore(moment(this.minDate, "YYYY-MM-DD", true), "day")) {
        return {valid: false, reason: "Data is available from " + this.minDateFormatted + "."};
      }

      return {valid: true};
    },
    validTimes() {
      const validStartTime = moment(this.startTime, "hh:dd:ss").isValid() && this.startTime.length === 8;
      const validEndTime = moment(this.endTime, "hh:mm:ss").isValid() && this.endTime.length === 8;

      if (!validStartTime || !validEndTime) return false;
      return true;
    },
    validDayParts() {
      // checks each dayPart for valid data format
      for (const part of this.dayParts) {
        if (part.daysOfWeek.length < 1) {
          return false;
        }

        const validStartTime = moment(part.startTime, "HH:mm", true).isValid();
        const validEndTime = moment(part.endTime, "HH:mm", true).isValid();
        if (!validStartTime || !validEndTime) {
          return false;
        }
      }

      return true;
    },
    startDateTime() {
      // returns the correct format for start date and time
      return `${this.startDate} ${this.startTime}`;
    },
    endDateTime() {
      // returns the correct format for end date and time
      return `${this.endDate} ${this.endTime}`;
    },
    timeframe() {
      // this is the timeframe object that will be submitted for the report
      const { startDateTime, endDateTime } = this;
      return {
        startDateTime,
        endDateTime,
      };
    },
    startEndDate() {
      return `${this.startDate} ${this.endDate}`;
    },
  },
  watch: {
    startEndDate() {
      //to highlight selected timeframe
      if (
        this.startDate === moment().subtract(31, "days").format("YYYY-MM-DD") &&
        this.endDate === this.twoDaysAgo
      ) {
        this.activeBtn = "30Days";
      } else if (
        this.startDate === moment().subtract(61, "days").format("YYYY-MM-DD") &&
        this.endDate === this.twoDaysAgo
      ) {
        this.activeBtn = "60Days";
      } else if (
        this.startDate ===
          moment()
            .subtract(2, "days")
            .subtract(3, "months")
            .format("YYYY-MM-DD") &&
        this.endDate === this.twoDaysAgo
      ) {
        this.activeBtn = "3Months";
      } else if (
        this.startDate ===
          moment()
            .subtract(2, "days")
            .subtract(6, "months")
            .format("YYYY-MM-DD") &&
        this.endDate === this.twoDaysAgo
      ) {
        this.activeBtn = "6Months";
      } else if (
        this.startDate ===
          moment()
            .subtract(2, "days")
            .subtract(1, "years")
            .format("YYYY-MM-DD") &&
        this.endDate === this.twoDaysAgo
      ) {
        this.activeBtn = "1Year";
      } else if (
        this.startDate ===
          moment()
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DD") &&
        this.endDate ===
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
      ) {
        this.activeBtn = "month";
      } else if (
        this.startDate ===
          moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD") &&
        this.endDate ===
          moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
      ) {
        this.activeBtn = "year";
      } else {
        this.activeBtn = "0";
      }
    },
    timeframe() {
      // clears dates error if dates are valid
      if (this.validDates.valid && this.validTimes && this.validDayParts) {
        bus.$emit("validTimeframe");
        this.$sessionStore.commit("setTimeframe", this.timeframe);
        this.errors = {};
        return;
      }

      if (!this.validDates.valid) {
        this.errors['date'] = this.validDates.reason;
        bus.$emit("validDateTime", this.validDates.reason);
      }

      if (!this.validTimes) {
        bus.$emit("validDateTime", "The time must be in the format hh:mm:ss");
        this.errors['time'] = "The time must be in the format hh:mm:ss";
      }

      this.$sessionStore.commit("setTimeframe", {
        startDateTime: "",
        endDateTime: "",
      });

    },
    dayParts() {
      // checks if dayParts data is valid and commits to store if so
      if (this.validDayParts) {
        this.$sessionStore.commit("setDayParts", this.dayParts);
      } else {
        this.$sessionStore.commit("setDayParts", undefined)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.error-msg {
  color: $red;
}
.timeframe {
  margin-bottom: 1.5em;
}
.calendar-container {
  width: 50%;
  margin-bottom: 1rem;
}
.days {
  font-size: 1.5em;
  text-align: center;
}
.modal-title {
  font-size: 1.25em;
}
.modal-content {
  font-size: 1em;
}

.dayparts-button {
  margin-top: 1rem;
}

.table {
  margin: 0;
}

#day-parts-collapse {
  margin-top: 1rem;
}
.btn-active {
  background-color: #2c7be5;
  color: white;
}
::v-deep .modal-backdrop {
  box-shadow: inset 0 0 2000px hwb(0deg 100% 0% / 50%) !important;
  background-color: rgb(22 34 68 / 50%) !important;
  backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}
</style>